import React from 'react';
import { skills } from '../../data/filters';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, Box, Chip, OutlinedInput } from '@mui/material';
import './Filters.scss';

type Props = {
  activeSkills: string[],
  onDeleteActiveSkill: (activeSkill: string) => void,
  onAddActiveSkill: (skill: string) => void
}

export const SkillFilter = ({ activeSkills, onDeleteActiveSkill, onAddActiveSkill }: Props) => { 
  const { design, dev, multipotentiality } = skills;
  const onMenuItemClick = (skill: string) => {
    activeSkills.includes(skill) ? 
      onDeleteActiveSkill(skill) : 
      onAddActiveSkill(skill)
  }
  
  return (
    <FormControl sx={{ flex: 1 }} size="small">
      <InputLabel htmlFor="skill-select">Skill</InputLabel>
      <Select 
        id="skill-select"
        multiple
        value={activeSkills}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {activeSkills.map((activeSkill, index) => (
              <Chip 
                key={index} 
                label={activeSkill} 
                onDelete={() => onDeleteActiveSkill(activeSkill)}
                onMouseDown={(event) => activeSkills.length > 0 && event.stopPropagation()}
              />
            ))}
          </Box>
        )}
      >
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Develop</ListSubheader>
        {dev.map((skill, index) => (
          <MenuItem 
            value={skill} 
            key={index} 
            onClick={() => onMenuItemClick(skill)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {skill}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Design</ListSubheader>
        {design.map((skill, index) => (
          <MenuItem 
            value={skill} 
            key={index}
            onClick={() => onMenuItemClick(skill)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {skill}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Multipotentialite</ListSubheader>
        {multipotentiality.map((skill, index) => (
          <MenuItem 
            value={skill} 
            key={index} 
            onClick={() => onMenuItemClick(skill)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {skill}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}