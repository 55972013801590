export const verbs = [
  'Develop',
  'Design',
  'Write',
];

export const skills = {
  design: [
    'Branding',
    'Data Visualization',
    'Graphics',
    'Mockups',
    'Research',
    'Sketching',
    'Wireframing',
    'Figma',
  ],
  dev: [
    'CSS in JS',
    'CSS',
    'D3',
    'Emotion',
    'Heroku',
    'HTML',
    'JavaScript',
    'NextJS',
    'Notion API',
    'p5',
    'React Native',
    'React',
    'Redux',
    'SCSS',
    'Storybook',
    'Styled Components',
    'Tailwind',
    'TypeScript',
    'Vercel',
  ],
  multipotentiality: [
    'Curriculum',
    'Storytelling',
    'Training a Model',
  ],
};

export const areasOfInterest = {
  education: [
    'Cognition',
    'Teaching',
  ],
  environment: [
    'Sustainability',
  ],
  holisticHealth: [
    'Fitness',
    'Perceptions of Beauty',
  ],
  productivity: [
    'Second Brain',
  ],
  ux: [
    'Accessibility',
    'Animation',
  ],
};
