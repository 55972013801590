import firebase from 'firebase/app';
import 'firebase/database';

export const TRACKING_ID = 'UA-168077027-1';
export const password = 'lMUf#8&P*08^N1@';

const config = {
  apiKey: 'AIzaSyDATM4oS6eQlgCAGXji8mH9uyCu31ncomo',
  authDomain: 'blog-37b7c.firebaseapp.com',
  databaseURL: 'https://blog-37b7c-default-rtdb.firebaseio.com/',
  projectId: 'blog-37b7c',
  storageBucket: 'blog-37b7c.appspot.com',
  messagingSenderId: '695129432963',
  appId: '1:695129432963:web:8989075d117bdb0e974cb6',
  measurementId: 'G-02FJ1F8VFY',
};

const app = firebase.initializeApp(config);
const db = app.database();
export default db;
