import React from 'react';
import './Form.scss';
import ConvertKitForm from 'convertkit-react';
import createEventTracker from '../../createEventTracker.tsx';

const formId = 1612631;

export default function Form() {
  const gaEventTracker = createEventTracker('header');

  return (
    <ConvertKitForm
      formId={formId}
      // template="clare"
      namePlaceholder="Name"
      emailPlaceholder="Email address"
      submitText="Subscribe to Writing"
      newTab
      className="subscribe-form"
      onClick={() => gaEventTracker('collaborate')}
    />
  );
}
