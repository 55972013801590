import React, { createContext, useContext, Dispatch, SetStateAction, useState } from 'react';
import { Home } from '../Home/Home';
import { Essay } from '../Essay/Essay';
import { DarkModeToggle } from '../../components/DarkModeToggle/DarkModeToggle';
import { Switch, Route } from "react-router-dom";
import 'styles/index.scss';
import ReactGA from 'react-ga';
import { TRACKING_ID } from '../../../firebase';

ReactGA.initialize(TRACKING_ID);

export type LinkType = {
  label: string;
  url: string;
}

export type ProjectType = {
  areasOfInterest?: string[],
  links?: LinkType[]
  passwordRequired?: boolean;
  pitch: string,
  skills?: string[],
  slug?: string,
  timeline: string,
  timeToRead?: number,
  title: string,
  verbs: string[],
  visual?: {
    type: string, 
    url: string,
  };
};

export enum ThemeType {
  Light = "light",
  Dark = "dark",
}

type ProjectsContextType = {
  projects: ProjectType[];
  setProjects: Dispatch<SetStateAction<ProjectType[]>>;
};

type ThemeContextType = {
  theme: ThemeType;
  setTheme: Function;
};

export const ProjectsContext = createContext<ProjectsContextType | null>(null);
export const ThemeContext = createContext<ThemeContextType>({
  theme: ThemeType.Light,
  setTheme: () => null,
})

// TODO: figure out why this isn't working
// export const setTheme = () => useContext(ThemeContext) as ThemeContextType;
export const useProjects = () => useContext(ProjectsContext) as ProjectsContextType;

const App = () => {
  const [theme, setTheme] = useState<ThemeType>(
    window.matchMedia("(prefers-color-scheme:dark)").matches 
    ? ThemeType.Dark : ThemeType.Light
  )

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={theme}>
        <DarkModeToggle />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path="/:slug" component={Essay} />
        </Switch>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
