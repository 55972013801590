import React from 'react';
import { verbs } from '../../data/filters';
import { FormControl, InputLabel, Select, MenuItem, Box, Chip, OutlinedInput } from '@mui/material';
import './Filters.scss';

type Props = {
  activeVerbs: string[],
  onDeleteActiveVerb: (activeVerb: string) => void,
  onAddActiveVerb: (verb: string) => void
}

export const VerbFilter = ({ activeVerbs, onDeleteActiveVerb, onAddActiveVerb }: Props) => { 
  return (
    <FormControl sx={{ flex: 1 }} size="small">
      <InputLabel htmlFor="verb-select">Verb</InputLabel>
      <Select
        id="verb-select"
        multiple
        value={activeVerbs}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {activeVerbs.map((activeVerb, index) => (
              <Chip 
                key={index} 
                label={activeVerb} 
                onDelete={() => onDeleteActiveVerb(activeVerb)}
                onMouseDown={(event) => activeVerbs.length > 0 && event.stopPropagation()}
              />
            ))}
          </Box>
        )}
      >
        {verbs.map((verb, index) => (
          <MenuItem
            key={index}
            value={verb}
            onClick={() => activeVerbs.includes(verb) ? onDeleteActiveVerb(verb) : onAddActiveVerb(verb)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {verb}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}