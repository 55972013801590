import React from 'react';
import { areasOfInterest } from '../../data/filters';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, Box, Chip, OutlinedInput } from '@mui/material';
import './Filters.scss';

type Props = {
  activeAreasOfInterest: string[],
  onDeleteAreaOfInterest: (activeAreaOfInterest: string) => void,
  onAddAreaOfInterest: (areaOfInterest: string) => void
}

export const AreasOfInterestFilter = ({ activeAreasOfInterest, onDeleteAreaOfInterest, onAddAreaOfInterest }: Props) => {
  const { education, environment, holisticHealth, productivity, ux } = areasOfInterest;
  const onMenuItemClick = (areaOfInterest: string) => {
    activeAreasOfInterest.includes(areaOfInterest) ? 
      onDeleteAreaOfInterest(areaOfInterest) : 
      onAddAreaOfInterest(areaOfInterest)
  }

  return (
    <FormControl sx={{ flex: 1 }} size="small">
      <InputLabel htmlFor="area-of-interest-select">Area Of Interest</InputLabel>
      <Select 
        id="area-of-interest-select"
        multiple
        value={activeAreasOfInterest}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {activeAreasOfInterest.map((activeAreaOfInterest, index) => (
              <Chip 
                key={index} 
                label={activeAreaOfInterest} 
                onDelete={() => onDeleteAreaOfInterest(activeAreaOfInterest)}
                onMouseDown={(event) => activeAreasOfInterest.length > 0 && event.stopPropagation()}
              />
            ))}
          </Box>
        )}
      >
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Education</ListSubheader>
        {education.map((areaOfInterest, index) => (
          <MenuItem 
            value={areaOfInterest} 
            key={index} 
            onClick={() => onMenuItemClick(areaOfInterest)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {areaOfInterest}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Environment</ListSubheader>
        {environment.map((areaOfInterest, index) => (
          <MenuItem 
            value={areaOfInterest}
            key={index} 
            onClick={() => onMenuItemClick(areaOfInterest)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {areaOfInterest}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Holistic Health</ListSubheader>
        {holisticHealth.map((areaOfInterest, index) => (
          <MenuItem 
            value={areaOfInterest}
            key={index} 
            onClick={() => onMenuItemClick(areaOfInterest)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {areaOfInterest}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Productivity</ListSubheader>
        {productivity.map((areaOfInterest, index) => (
          <MenuItem 
            value={areaOfInterest} 
            key={index} 
            onClick={() => onMenuItemClick(areaOfInterest)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {areaOfInterest}
          </MenuItem>
        ))}
        <ListSubheader sx={{ textTransform: 'uppercase', opacity: '.6' }}>Experience</ListSubheader>
        {ux.map((areaOfInterest, index) => (
          <MenuItem 
            value={areaOfInterest} 
            key={index} 
            onClick={() => onMenuItemClick(areaOfInterest)}
            sx={{ fontFamily: 'IBM Plex Mono, monospace' }}
          >
            {areaOfInterest}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}