import React from 'react';
import './Button.scss';

type Props = {
  content: string,
  onclick?: () => void,
  classNames?: string,
  kind: 'primary' | 'secondary',
}

export const Button = ({ content, onclick, classNames, kind }: Props) =>
  <button type="button" className={`button ${kind} ${classNames}`} onClick={ onclick } aria-label={ content }>
    { content }
  </button>
