import React, { useState } from "react";
import { Grid, Fade } from "@mui/material";
import { Link } from "react-router-dom";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import { PasswordModal } from "../PasswordModal/PasswordModal";
import { Button } from "../Button/Button";
import createEventTracker from "../../createEventTracker";
import { LinkType, ProjectType, useProjects } from "../../pages/App/App";
import "./Projects.scss";

export const Projects = () => {
  const [passwordRequiredModal, setPasswordRequiredModal] = useState(false);
  const [projectDetails, setProjectDetails] = useState<ProjectType>({
    title: "",
    pitch: "",
    timeline: "",
    verbs: [""],
  });
  const gaEventTracker = createEventTracker("projects");
  const { projects } = useProjects();

  return projects.length === 0 ? (
    <p className="margin">
      There are no projects for the specified filters. Try clearing the filters
      and picking different ones.
    </p>
  ) : (
    <div className="margin">
      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={3}
        columnSpacing={2}
      >
        {projects.map((project, index) => (
          <Fade key={index} in timeout={1000}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <div className={`project ${project.passwordRequired && "blur"}`}>
                {project.passwordRequired && (
                  <Button
                    kind="primary"
                    classNames="password"
                    content="Unlock Project"
                    onclick={() => {
                      setProjectDetails(project);
                      setPasswordRequiredModal(true);
                    }}
                  />
                )}
                {project.visual && project.visual.type === "image" && (
                  <img
                    alt={`${project.title} overview`}
                    src={`/assets/images/projects/${project.visual.url}.svg`}
                    loading={index > 6 ? "lazy" : "eager"}
                  />
                )}
                {project.visual && project.visual.type === "video" && (
                  <video autoPlay loop playsInline muted>
                    <source
                      src={`/assets/videos/projects/${project.visual.url}.mp4`}
                      type="video/mp4"
                    />
                  </video>
                )}
                <div
                  className={`heading ${
                    project.visual ? "visual" : "no-visual"
                  }`}
                >
                  <h3>
                    {project.passwordRequired
                      ? "Project Title Hidden"
                      : project.title}
                  </h3>
                  {!project.passwordRequired && (
                    <p className="timeline">{project.timeline}</p>
                  )}
                </div>
                <p className="pitch">
                  {project.passwordRequired
                    ? "This is normally where the project description would go, but this project is locked. To view it, click on the 'Unlock Project' button."
                    : project.pitch}
                </p>
                <p className="links">
                  {project.verbs[0] === "Write" ? (
                    <Link className="link" to={`/${project.slug}`}>
                      Read more
                    </Link>
                  ) : (
                    project.links &&
                    !project.passwordRequired &&
                    project.links.map((link: LinkType, i: number) => (
                      <ExternalLink
                        key={i}
                        content={link.label}
                        url={link.url}
                        onclick={() => gaEventTracker(project.title)}
                        newTab={true}
                      />
                    ))
                  )}
                  {project.passwordRequired && (
                    <ExternalLink
                      content={"Links hidden"}
                      url={"/"}
                      newTab={false}
                    />
                  )}
                </p>
              </div>
            </Grid>
          </Fade>
        ))}
      </Grid>
      {passwordRequiredModal && (
        <PasswordModal
          projectDetails={projectDetails}
          setPasswordRequiredModal={setPasswordRequiredModal}
        />
      )}
    </div>
  );
};
