import React, { useState, useEffect } from 'react';
import { useProjects } from '../../pages/App/App';
import allProjects from '../../data/projects';
import { VerbFilter } from './VerbFilter';
import { SkillFilter } from './SkillFilter';
import { AreasOfInterestFilter } from './AreasOfInterestFilter';
import { throttle } from 'lodash';
import './Filters.scss';

export const Filters = () => {
  const { setProjects } = useProjects()
  const [filters, setFilters] = useState<string[]>([])
  const [activeVerbs, setActiveVerbs] = useState<string[]>([]);
  const [activeSkills, setActiveSkills] = useState<string[]>([]);
  const [activeAreasOfInterest, setActiveAreasOfInterest] = useState<string[]>([]);
  const [needShadow, setNeedShadow] = useState(false);

  const applyShadow = () => {
    if(window.innerWidth > 768) {
      const projects = document.querySelector('.right')
      if(projects) {
        const updatePosition = () => {
          projects.scrollTop > 19 ? setNeedShadow(true) : setNeedShadow(false) 
        }
        projects.addEventListener("scroll", throttle(updatePosition, 700));
        return () => document.removeEventListener("scroll", updatePosition);
      } 
    } else {
        const distanceFromFilters = document.querySelector('.left')?.clientHeight
        if(distanceFromFilters) {
          const updatePosition = () => {
            window.scrollY > distanceFromFilters ? setNeedShadow(true) : setNeedShadow(false) 
          }
          document.addEventListener("scroll", throttle(updatePosition, 700));
          return () => document.removeEventListener("scroll", updatePosition);
        }
    }  
  }

  useEffect(() => applyShadow(), []);

  useEffect(() => {
    window.addEventListener("resize", applyShadow);
    return () => window.removeEventListener("resize", () => applyShadow());
  }, []);

  useEffect(() => {
    filters.length === 0 ?
      setProjects(allProjects)
      :
      setProjects(
        allProjects.filter((project) => 
          filters.some((value) => 
            (
              project.verbs.includes(value) || 
              project.skills?.includes(value) || 
              project.areasOfInterest?.includes(value)
            )
          )
        )
      )
  }, [filters, setProjects])

  const onAddActiveVerb = (verb: string) => {
    setFilters(prevState => [...prevState, verb])
    setActiveVerbs(prevState => [...prevState, verb])
  }

  const onDeleteActiveVerb = (activeVerb: string) => {
    setFilters(prevState => prevState.filter(item => item !== activeVerb))
    setActiveVerbs(prevState => prevState.filter(item => item !== activeVerb))
  }

  const onAddActiveSkill = (skill: string) => {
    setFilters(prevState => [...prevState, skill])
    setActiveSkills(prevState => [...prevState, skill])
  }

  const onDeleteActiveSkill = (activeSkill: string) => {
    setFilters(prevState => prevState.filter(item => item !== activeSkill))
    setActiveSkills(prevState => prevState.filter(item => item !== activeSkill))
  }

  const onAddAreaOfInterest = (skill: string) => {
    setFilters(prevState => [...prevState, skill])
    setActiveAreasOfInterest(prevState => [...prevState, skill])
  }

  const onDeleteAreaOfInterest = (activeSkill: string) => {
    setFilters(prevState => prevState.filter(item => item !== activeSkill))
    setActiveAreasOfInterest(prevState => prevState.filter(item => item !== activeSkill))
  }

  return (
    <div className={`filters-container ${needShadow && 'shadow'}`}>
      <h2 className='bold title'>Filter projects by:</h2>
      <div className='filters'>
        <VerbFilter
          activeVerbs={activeVerbs}
          onAddActiveVerb={onAddActiveVerb}
          onDeleteActiveVerb={onDeleteActiveVerb}
        />
        <SkillFilter
          activeSkills={activeSkills}
          onAddActiveSkill={onAddActiveSkill}
          onDeleteActiveSkill={onDeleteActiveSkill}
        />
        <AreasOfInterestFilter
          activeAreasOfInterest={activeAreasOfInterest}
          onAddAreaOfInterest={onAddAreaOfInterest}
          onDeleteAreaOfInterest={onDeleteAreaOfInterest}
        />
      </div>
    </div>
  );
}