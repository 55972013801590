import React from 'react';
import './OpeningText.scss';
import { Header } from '../Header/Header';
import { ExternalLink } from '../ExternalLink/ExternalLink'

export const OpeningText = () => {
  return (
    <div className="opening-text">
      <span role="img" aria-label="Female Technologist Emoji" className="emoji">👩🏽‍💻</span>
      <h1>Hi I'm Shemona, I design with code.</h1>
      <p className="bio">
        I work best in spaces that coalesce art with science, encourage wild 
        thinking, and are driven by missions tackling difficult questions. 
        I'm currently helping democratize access to fruits and vegetables at{' '}
        <ExternalLink 
          url="https://source.ag/" 
          content="Source" 
          newTab={true} 
        />.
        For more technical writing, visit my{' '}
        <ExternalLink 
          url="https://dev.to/singhshemona" 
          content="development blog" 
          newTab={true} 
        />.
      </p>
      <Header />
    </div>
  );
}
