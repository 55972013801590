import React from 'react';
import './ExternalLink.scss';

type Props = {
  content: any,
  url: string,
  onclick?: () => void,
  newTab: boolean,
}

export const ExternalLink = ({ url, content, onclick, newTab }: Props) =>
  <a 
    className='link' 
    href={url} 
    rel="noopener noreferrer"
    target={newTab ? "_blank" : "_self"} 
    onClick={onclick}
  >
    {content}
  </a>
