import React from 'react';
import './Header.scss';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import createEventTracker from '../../createEventTracker';

export const Header = () => {
  const gaEventTracker = createEventTracker('header');

  return (
    <div className='icons'>
      <ExternalLink
        content={<img src='assets/images/logos/github.svg' alt="Github Logo" />}
        url="https://github.com/singhshemona"
        onclick={() => gaEventTracker('github')}
        newTab={true}
      />
      <ExternalLink
        content={<img src='assets/images/logos/devto.svg' alt="DevTo Logo" />}
        url="https://dev.to/singhshemona"
        onclick={() => gaEventTracker('devto')}
        newTab={true}
      />
      <ExternalLink
        content={<img src='assets/images/logos/behance.svg' alt="Behance Logo" />}
        url="https://www.behance.net/singhshemona/projects"
        onclick={() => gaEventTracker('behance')}
        newTab={true}
      />
      <ExternalLink
        content={<img src='assets/images/logos/linkedin.svg' alt="Linkedin Logo" />}
        url="https://www.linkedin.com/in/shemonasingh/"
        onclick={() => gaEventTracker('linkedin')}
        newTab={true}
      />
      <ExternalLink
        content={<img src='assets/images/logos/anchor.svg' alt="Anchor Logo" />}
        url="https://anchor.fm/third-eye-thoughts"
        onclick={() => gaEventTracker('anchor')}
        newTab={true}
      />
    </div>
  );
}
