import React, { useState } from 'react';
import { LinkType, ProjectType } from '../../pages/App/App';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { Button } from '../Button/Button';
import createEventTracker from '../../createEventTracker';
import { password } from '../../../firebase';
import './PasswordModal.scss';

type Props = {
  projectDetails: ProjectType;
  setPasswordRequiredModal: (close: boolean) => void;
}

export const PasswordModal = ({ projectDetails, setPasswordRequiredModal }: Props) => {
  // const passwordRef = useRef<HTMLDivElement>(null);
  const gaEventTracker = createEventTracker('projects');
  const [userInput, setUserInput] = useState<string>('');
  const [shake, setShake] = useState(false);
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const checkPassword = (userInput: string) => {
    if(userInput === password) {
      setShowError(false)
      setShowConfirmation(true)
      setPasswordCorrect(true)
    } else {
      setShake(true)
      setShowError(true)
      setShowConfirmation(false)
      setPasswordCorrect(false)
    }
  }

  // useEffect(() => {
  //   const handleClick = (event: MouseEvent | TouchEvent) => {
  //     if ((passwordRef.current)?.contains(event.target as Node)) {
  //       console.log('contains')
  //       return;
  //     } else setPasswordRequiredModal(false);
  //   };

  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, [setPasswordRequiredModal]);

  return (
    // <div className="password-modal" ref={passwordRef}>
    <div className="password-modal">
      <div className="overflow">
        <Button
          kind='secondary'
          content='X'
          classNames='close'
          onclick={() => setPasswordRequiredModal(false)}
        />
        <p>For proprietary reasons, this project is password protected.</p>
        <p>If you have the password, you can enter it here and continue to view the details of this project.</p>
        <form className={`form ${shake && 'shaking'}`} onAnimationEnd={() => setShake(false)}>
          <input
            name="password"
            onChange={(event) => setUserInput(event.target.value)}
            type="password"
            value={userInput}
            aria-label='password input'
          />
          <Button kind='secondary' classNames="submit" onclick={() => checkPassword(userInput)} content='Verify password' />
        </form>
        {showError && <p className="error">Looks like that's the wrong password. Maybe you typed it wrong?</p>}
        {showConfirmation && <p className="success">Password confirmed! Here is the requested project:</p>}
        {passwordCorrect && projectDetails.links && (
          <div className='project'>
            {projectDetails.visual && 
              projectDetails.visual.type === 'image' && 
                <img 
                  alt={`${projectDetails.title} overview`} 
                  src={`/assets/images/projects/${projectDetails.visual.url}.svg`} 
                />
            }
            {projectDetails.visual && 
              projectDetails.visual.type === 'video' &&
                <video autoPlay loop playsInline muted> 
                  <source src={`/assets/videos/projects/${projectDetails.visual.url}.mp4`} type="video/mp4" /> 
                </video> 
            }
            <div className={`heading ${(projectDetails.visual) ? 'visual' : 'no-visual'}`}>
              <h3>{projectDetails.title}</h3>
              <p className="timeline">{projectDetails.timeline}</p>
            </div>
            <p className="pitch">{projectDetails.pitch}</p> 
            <p className="links">
            {projectDetails.links && 
                  projectDetails.links.map((link: LinkType, i: number) => (
                    <ExternalLink
                      key={i}
                      content={link.label}
                      url={link.url}
                      onclick={() => gaEventTracker(projectDetails.title)}
                      newTab={true}
                    />
                  ))
              }
            </p>
          </div>
        )}
        {!showConfirmation &&
          <p className="bio">If you don't have it, you can 
            <Button
              classNames='exit'
              kind='secondary'
              content='exit'
              onclick={() => setPasswordRequiredModal(false)}
            /> 
            out of this modal and look into other works of mine, as many are available for public viewing.
          </p>
        }
      </div>
    </div>
  );
}
