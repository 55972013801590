export default [
  // {
  //   image: 'https://firebasestorage.googleapis.com/v0/b/blog-37b7c.appspot.com/o/recommend.svg?alt=media&token=914fedd0-64b2-4313-8fb1-5eae8958b8e0',
  //   url: 'https://github.com/singhshemona/recommend',
  //   pitch: 'Eliminating the bumpers of confirmation bias in current recommendation engines.',
  //   skills: ['Wireframing', 'Mockups', 'D3', 'TypeScript', 'React', 'Systems Design', 'SCSS'],
  //   timeline: 'Jan 2021 to Present',
  //   title: 'Broadening Recommendations',
  //   verbs: ['Design', 'Develop'],
  // },
  {
    links: [
      {
        label: 'Code',
        url: 'https://github.com/singhshemona/the-beholder',
      },
      {
        label: 'App',
        url: 'https://the-beholder.vercel.app/',
      },
    ],
    passwordRequired: false,
    areasOfInterest: ['Perceptions of Beauty'],
    pitch:
      'Highlighting perceived beauty as transistory celebrations rather than elusive misalignments.',
    skills: [
      'TypeScript',
      'React',
      'Vercel',
      'Data Visualization',
      'Research',
      'CSS',
      'p5',
      'Training a Model',
    ],
    timeline: 'Aug 2023 to Oct 2023',
    title: 'The Beholder',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'video',
      url: 'beholder',
    },
  },
  {
    links: [
      {
        label: 'Code',
        url: 'https://github.com/singhshemona/network',
      },
      {
        label: 'App',
        url: 'https://network-pi.vercel.app/',
      },
    ],
    passwordRequired: false,
    areasOfInterest: ['Cognition', 'Second Brain', 'Teaching'],
    pitch:
      'An exploration into how we might learn more effectively, inspired by connectivism.',
    skills: [
      'TypeScript',
      'Styled Components',
      'React',
      'Figma',
      'Mockups',
      'Vercel',
    ],
    timeline: 'Mar 2021 to Aug 2023',
    title: 'Adjacent',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'video',
      url: 'adjacent',
    },
  },
  {
    links: [
      {
        label: 'Figma',
        url: 'https://drive.google.com/file/d/13HvCYVTcrAQzHrwwxJefpDXXZsMR807I/view?usp=sharing',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1vLU4DbUFZcsa6DcJRKvPKxQHcXHixRGW?usp=sharing',
      },
    ],
    passwordRequired: true,
    pitch: 'An improved investment experience for data onboarding.',
    skills: ['Figma', 'Mockups'],
    timeline: 'Dec 2022',
    title: 'Union Invest',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'union-invest',
    },
  },
  {
    links: [
      {
        label: 'Figma',
        url: 'https://drive.google.com/file/d/16xn1noC0NAIlnXK0NbKRfYfFq-4gyjm2/view?usp=sharing',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1afWBVDBHOJwf0kVlR7TeZhsvjUmLj2p_?usp=sharing',
      },
    ],
    passwordRequired: true,
    pitch:
      'Exploring how we might update our deployment process UI with a tight deadline.',
    skills: ['Figma', 'Mockups', 'Data Visualization', 'Research'],
    timeline: 'Nov 2022 to Dec 2022',
    title: 'Kuberpult',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'kuberpult',
    },
  },
  {
    pitch:
      'When you first learned to bowl, you likely had the bumpers up. They prevented you from landing in a situation you don’t want. Similarly, in life we can create bumpers to reduce the impact of an unwanted and/or unexpected force.',
    slug: 'bumpers',
    timeline: 'Jan 2023',
    timeToRead: 15,
    title: 'Bumpers',
    verbs: ['Write'],
  },
  {
    links: [
      {
        label: 'Details',
        url: 'https://drive.google.com/drive/folders/10z_mTO1DBIqjpFyqsrmBhxC5KZkQX4Xd?usp=sharing',
      },
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/advent-calendar.mp4',
      },
    ],
    passwordRequired: true,
    pitch: 'Generate excitment around holiday sales for P&C.',
    skills: ['React', 'TypeScript', 'Tailwind'],
    timeline: 'Oct 2022 to Dec 2022',
    title: 'Advent Calendar',
    verbs: ['Develop'],
    visual: {
      type: 'video',
      url: 'advent-calendar',
    },
  },
  {
    links: [
      {
        label: 'Website',
        url: 'https://www.peek-cloppenburg.de/',
      },
      {
        label: 'Publicity',
        url: 'https://cloud.google.com/customers/peek-cloppenburg',
      },
      {
        label: 'Storybook',
        url: 'https://drive.google.com/drive/folders/1BH2NutL-iNo0Jl127XDp032C1WYv8yQq?usp=sharing',
      },
    ],
    pitch: 'Launching the next generation of fashion for a retail giant.',
    skills: ['React', 'TypeScript', 'Tailwind', 'Storybook'],
    timeline: 'Jun 2022 to December 2022',
    title: 'Peek & Cloppenburg',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'peek-and-cloppenburg',
    },
  },
  {
    links: [
      {
        label: 'Codepen',
        url: 'https://codepen.io/singhshemona/pen/bGaZyre',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1DxqKcymsR6d3nMPlzxCnmwQT4cfiqteJ?usp=sharing',
      },
    ],
    pitch:
      "Allow Employers to quickly search through SMB's numerous resources.",
    skills: ['Research', 'Wireframing', 'Mockups', 'HTML', 'CSS', 'JavaScript'],
    timeline: 'Apr 2022',
    title: 'Search Bar',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'search-bar',
    },
  },
  {
    links: [
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1VJyEmF_fmf04MHRKtkaCR_d_zE1Jivfh?usp=sharing',
      },
    ],
    pitch:
      'Guide Employers to relevant, valuable next steps and educational resources reaching greater hiring outcomes.',
    skills: ['Research', 'Wireframing', 'Mockups'],
    timeline: 'Apr 2022',
    title: 'Next Step Guide',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'next-step-guide',
    },
  },
  {
    links: [
      {
        label: 'Codepen',
        url: 'https://codepen.io/singhshemona/pen/oNExeeZ',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1VxK0q7seNtJy5TSPequ9KnyX0e57eK1I?usp=sharing',
      },
    ],
    pitch:
      'An exploration to unbury connected articles as part of the overall hiring experience.',
    skills: ['Research', 'Wireframing', 'Mockups', 'HTML', 'CSS', 'JavaScript'],
    timeline: 'Mar 2022',
    title: 'Sticky Table of Contents',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'sticky-table-of-contents',
    },
  },
  {
    links: [
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1XK_DOtRXEsUTY0mu8OknNv1DhaPbvdLy?usp=sharing',
      },
    ],
    pitch:
      'Rethinking architectural viability of the countless resources Indeed has for employers.',
    skills: ['Research', 'Wireframing', 'Mockups'],
    timeline: 'Jan 2022 to Mar 2022',
    title: 'Resource Category Pages',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'resource-category-pages',
    },
  },
  {
    links: [
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/job-description-builder.mp4',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1Nk1Vl_ee1a_wro8BxPzUNMHebbLpJfwi?usp=sharing',
      },
    ],
    pitch:
      'Faster establish context between job description learnings and posting a job.',
    skills: ['React', 'SCSS', 'TypeScript', 'Mockups'],
    timeline: 'Jan 2022 to Apr 2022',
    title: 'Job Description Builder',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'video',
      url: 'job-description-builder',
    },
  },
  {
    links: [
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/availability-scheduling.mp4',
      },
    ],
    pitch:
      'Bringing clarity to an often chaotic part of hiring - scheduling interviews.',
    skills: ['React', 'TypeScript', 'Emotion'],
    timeline: 'Oct 2021 to Dec 2021',
    title: 'Availability Scheduling',
    verbs: ['Develop'],
    visual: {
      type: 'video',
      url: 'availability-scheduling',
    },
  },
  {
    areasOfInterest: ['Cognition'],
    pitch:
      'In Liquid Thinking, I discuss the unexpected connections between our branches of knowledge. How various facets of you can come together in unimaginable ways. But how exactly does one come to notice that connection?',
    slug: 'how-we-learn',
    timeline: 'Oct 2021',
    timeToRead: 32,
    title: 'How We Learn',
    verbs: ['Write'],
  },
  {
    links: [
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/two-pane.mp4',
      },
    ],
    pitch:
      'An all-in-one candidate management experience - review applications, message candidates, take notes, and filter promising options in bulk.',
    skills: ['React', 'TypeScript', 'Emotion'],
    timeline: 'Jul 2021 to Sept 2021',
    title: 'Two Pane',
    verbs: ['Develop'],
    visual: {
      type: 'video',
      url: 'two-pane',
    },
  },
  {
    areasOfInterest: ['Second Brain, Cognition'],
    links: [
      {
        label: 'App',
        url: 'https://spark-v2-client.vercel.app/',
      },
      {
        label: 'Code',
        url: 'https://github.com/singhshemona/spark-v2',
      },
    ],
    pitch: 'Pleasant reminders of saved highlights from years of learning.',
    skills: ['NextJS', 'React', 'Heroku', 'Notion API', 'SCSS'],
    timeline: 'Jun 2021 to Sept 2021',
    title: 'Spark',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'video',
      url: 'spark',
    },
  },
  {
    pitch:
      "This is a topic that's been on my mind in some capacity for years now. I've hesitated translating it from thoughts to words for some time now, mostly because I wasn't sure how to describe what I was feeling.",
    slug: 'context-muddies-content',
    timeline: 'Aug 2021',
    timeToRead: 6,
    title: 'When Context Muddies the Waters of Content',
    verbs: ['Write'],
  },
  {
    areasOfInterest: ['Sustainability'],
    links: [
      {
        label: 'Code',
        url: 'https://github.com/singhshemona/capsule',
      },
    ],
    pitch:
      'Enables traveling light and expressing style to be mutually inclusive.',
    skills: ['React Native', 'CSS in JS', 'Wireframing'],
    timeline: 'Feb 2021 to Apr 2021',
    title: 'Build My Capsule',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'build-my-capsule',
    },
  },
  {
    pitch:
      'Writing has been a craft close to my heart for as long as I could speak. The interest sparked with creative writing, where my amazement with bringing to life magical universes spiraling within my mind never ceased.',
    slug: 'why-i-write',
    timeline: 'Apr 2021',
    timeToRead: 7,
    title: 'Why I Write',
    verbs: ['Write'],
  },
  {
    links: [
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/audio-intro.mp4',
      },
    ],
    pitch:
      'Giving candidates a way to introduce themselves in a more personal manner.',
    skills: ['React', 'TypeScript', 'Emotion'],
    timeline: 'Mar 2021 to Jun 2021',
    title: 'Audio Intro',
    verbs: ['Develop'],
    visual: {
      type: 'image',
      url: 'audio-intro',
    },
  },
  {
    pitch:
      'Towards the middle of 2020 I stumbled upon the term "second brain." I was instantly intrigued. Much like most of the worlds I stumble into, in some ways I had been opening its doors long before I learnt its principles.',
    slug: 'applying-zettelkasten-to-notion',
    timeline: 'Mar 2021',
    timeToRead: 12,
    title: 'Applying Zettelkasten to Notion',
    verbs: ['Write'],
  },
  {
    pitch:
      'How we adopt information into our lives determines how long we retain it for.',
    slug: 'how-to-take-non-academic-notes',
    timeline: 'Mar 2021',
    timeToRead: 10,
    title: 'How to Take Non-Academic Notes',
    verbs: ['Write'],
  },
  {
    areasOfInterest: ['Animation', 'Accessibility'],
    links: [
      {
        label: 'Code',
        url: 'https://github.com/singhshemona/animate',
      },
      {
        label: 'Storybook',
        url: 'https://5fd129c66ec81a0021cac291-byenvlvszc.chromatic.com/?path=/story/documentation-colors-used--palette',
      },
    ],
    pitch: 'Easily adaptable, accessible and performant animation templates.',
    skills: [
      'Research',
      'Wireframing',
      'TypeScript',
      'React',
      'SCSS',
      'Storybook',
    ],
    timeline: 'Feb 2020 to Nov 2020',
    title: 'Animations Library',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'animations-library',
    },
  },
  {
    pitch:
      "We've reached a point in technological development where our devices understand how to satiate our desires more than any human ever will. We're drowning in information that's filtered through our unique perspectives.",
    slug: 'our-strongest-armor',
    timeline: 'Feb 2021',
    timeToRead: 11,
    title: 'Our Strongest Armor',
    verbs: ['Write'],
  },
  {
    pitch:
      'They are not necessarily mental models so much as they are the instinctive ways we explain the world and its happenings to our brains. Because they influence our instincts, they feed the everyday reactions we have and determine the vibrations we reverberate.',
    slug: 'changing-the-narrative',
    timeline: 'Sept 2020',
    timeToRead: 9,
    title: 'Changing the Narrative',
    verbs: ['Write'],
  },
  {
    pitch:
      'When faced with a decision, ask yourself: What seeds will this action plant, which buds will this water, or what flowers will this uproot?',
    slug: 'create',
    timeline: 'Jun 2020',
    timeToRead: 8,
    title: 'Create',
    verbs: ['Write'],
  },
  {
    areasOfInterest: ['Cognition'],
    pitch:
      'Understanding and appreciating various networks of knowledge while striving to be a liquid thinker can aid in sparking innovation and removing intimidating barriers to explore an unfamiliar territory of knowledge.',
    slug: 'liquid-thinking',
    timeline: 'May 2020',
    timeToRead: 11,
    title: 'Liquid Thinking',
    verbs: ['Write'],
  },
  {
    areasOfInterest: ['Teaching'],
    links: [
      {
        label: 'Course',
        url: 'https://scrimba.com/playlist/pNpEbsP',
      },
    ],
    pitch: 'A breakdown of simple uses for Redux with React to build upon.',
    skills: ['Storytelling', 'Research', 'Curriculum', 'React', 'Redux'],
    timeline: 'May 2020 to Aug 2020',
    title: 'Redux Course',
    verbs: ['Develop'],
  },
  {
    pitch:
      'Rather than chastise for not being aware, lets foster inclusivity by welcoming all that we can learn from others.',
    slug: 'finding-harmony-in-polarity',
    timeline: 'Apr 2020',
    timeToRead: 8,
    title: 'Finding Harmony in Polarity',
    verbs: ['Write'],
  },
  {
    links: [
      {
        label: 'Code',
        url: 'https://github.com/homegrade/HomeGrade',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1WHokdVDtw-X0TGYsTo1jHlAColBRpyDS?usp=share_link',
      },
    ],
    pitch: "Creating a more approachable way to assess a home's value.",
    skills: ['Wireframing', 'Research', 'Mockups', 'React', 'Emotion'],
    timeline: 'Apr 2019 to Apr 2020',
    title: 'HomeGrade',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'homegrade',
    },
  },
  {
    links: [
      {
        label: 'Website',
        url: 'https://shemonasingh.com/',
      },
    ],
    pitch:
      'A digital archive of where my curiousity has led me throughout the years.',
    skills: ['Wireframing', 'Mockups', 'React', 'TypeScript', 'SCSS'],
    timeline: 'Mar 2019 to Present',
    title: 'My Portfolio Website',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'video',
      url: 'portfolio',
    },
  },
  {
    links: [
      {
        label: 'Walkthrough',
        url: 'https://shemonasingh.com/assets/videos/projects/employer-help-center.mp4',
      },
      {
        label: 'Website',
        url: 'https://indeed.force.com/employerSupport1/s/?language=en_US',
      },
    ],
    pitch:
      'Clearing the path for Employers to seek for help when stuck in the hiring process.',
    skills: ['HTML', 'CSS', 'JavaScript'],
    timeline: 'Jan 2019 to Mar 2019',
    title: 'Employer Help Center',
    verbs: ['Develop'],
    visual: {
      type: 'video',
      url: 'employer-help-center',
    },
  },
  {
    links: [
      {
        label: 'Code',
        url: 'https://github.com/makerpaper/makerpaper.github.io',
      },
    ],
    pitch:
      'A mechanism for artists to more easily package and ship their work.',
    skills: ['Research', 'Mockups', 'JavaScript', 'HTML', 'SCSS'],
    timeline: 'Nov 2018 to Dec 2019',
    title: 'Makerpaper',
    verbs: ['Develop'],
    visual: {
      type: 'image',
      url: 'makerpaper',
    },
  },
  {
    areasOfInterest: ['Sustainability'],
    links: [
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1W64gmhoIs4eOaOaxW_tWl7ygQ1et6Bl2?usp=share_link',
      },
    ],
    pitch:
      'Grouping food orders for more efficient deliveries and closer friends.',
    skills: ['Research', 'Mockups', 'Wireframes'],
    timeline: 'Aug 2018 to Dec 2018',
    title: 'Munchbunch',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'munchbunch',
    },
  },
  {
    areasOfInterest: ['Fitness'],
    links: [
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1hiltqCPpEoVEdrSixIuDyNxjSTg3JqZo?usp=sharing',
      },
    ],
    pitch: 'Search engine for fitness classes in countless cities nationwide.',
    skills: [
      'Research',
      'Wireframes',
      'Mockups',
      'Branding',
      'Graphics',
      'React',
      'SCSS',
    ],
    timeline: 'Jun 2017 to Aug 2017',
    title: 'Fit My Schedule',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'fit-my-schedule',
    },
  },
  {
    links: [
      {
        label: 'Website',
        url: 'https://www.uctv14.com/',
      },
      {
        label: 'Designs',
        url: 'https://drive.google.com/drive/folders/1ztynfzp4MZx90vMou29rdGELpUruI9lI?usp=sharing',
      },
    ],
    pitch:
      'A new look for the UConn student organization with the biggest media presence on campus.',
    skills: [
      'Research',
      'Wireframes',
      'Mockups',
      'Branding',
      'HTML',
      'CSS',
      'JavaScript',
    ],
    timeline: 'Dec 2016 to May 2017',
    title: 'UConn Student Television',
    verbs: ['Design', 'Develop'],
    visual: {
      type: 'image',
      url: 'uctv',
    },
  },
  {
    links: [
      {
        label: 'Magazine',
        url: 'https://www.yumpu.com/en/document/read/60060912/v2',
      },
    ],
    pitch:
      "UConn's first magazine reporting current events and opinion pieces in the field of STEM.",
    skills: ['Wireframes', 'Graphics', 'Branding'],
    timeline: 'Oct 2016 to May 2018',
    title: 'STEMTalk',
    verbs: ['Design'],
    visual: {
      type: 'image',
      url: 'stemtalk',
    },
  },
];
