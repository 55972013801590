import React, { useState } from 'react'
import { OpeningText } from '../../components/OpeningText/OpeningText'
import Form from '../../components/Form/Form'
import { Projects } from '../../components/Projects/Projects'
import { Filters } from '../../components/Filters/Filters'
import allProjects from '../../data/projects';
import { ProjectsContext, ProjectType } from '../App/App'
import './Home.scss';

export const Home = () => {
  const [projects, setProjects] = useState<ProjectType[]>(allProjects)

  return (
    <div className="home-page">
      <div className="left">
        <OpeningText />
        <Form />
      </div>
      <div className="right">
        <ProjectsContext.Provider value={{ projects, setProjects }}>
          <Filters />
          <Projects />
        </ProjectsContext.Provider>
      </div>
    </div>
  );
}