import React, { useState, useEffect } from 'react';
import { EssayContainer } from '../../components/EssayContainer/EssayContainer';
import { Link } from "react-router-dom";
import db from '../../../firebase';
import './Essay.scss';
import ReactGA from 'react-ga';
import { TRACKING_ID } from '../../../firebase';

type Props = {
  match: any
}

export const Essay = ({ match }: Props) => {
  const slug = match.params.slug;
  const [ currentPost, setCurrentPost ] = useState<null | {title: string, content: string, timeToRead: number, lastEdited: string, published: string}>(null);
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    db.ref()
      .child(`/posts/${slug}`)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          setCurrentPost(snapshot.val())
        }  
      });
  }, [slug]);

  const postDoesNotExist = !currentPost;

  return (
    <div className={`essay-page ${postDoesNotExist ? 'full-height' : ''}`}>
      {postDoesNotExist ? 
        <div className='top-link missing-post'>
          <h4 className='loading'>Loading</h4>
          <h4>If it's been more than 5 seconds, this page might not exist.</h4>
          <Link className='link' to="/">Head back to see which ones do!</Link>
        </div>
        :
        <>
          <h4 className="top-link">
            <Link className='link' to="/">← BACK TO ALL ESSAYS</Link>
          </h4>
          <EssayContainer 
            title={currentPost ? currentPost.title : 'No Title'} 
            content={currentPost ? currentPost.content : 'No Content'} 
            timeToRead={currentPost ? currentPost.timeToRead : 0}
            lastEdited={currentPost ? currentPost.lastEdited : 'No Last Edited Date'}
            published={currentPost ? currentPost.published : 'No Last Published Date'}
          />
          <h4 className="bottom-link">
            <Link className='link' to="/">← BACK TO ALL ESSAYS</Link>
          </h4>
        </>
      }
    </div>
  );
}