import ReactGA from "react-ga";

const createEventTracker = (category="category") => {
  const eventTracker = (action = "action", label = "label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}

export default createEventTracker;
